import React, { memo } from 'react';
import { M20CategoriesModule } from '~/lib/data-contract';
import { weakKey } from '~/shared/utils/jsx';
import { Category } from '~/templates/blocks/components/Category';
import { ModuleContainer } from '~/templates/blocks/components/ModuleContainer';
import { itemMapper } from '~/templates/blocks/utils/itemMapper';
import { useContrastThemeShade } from '~/theme';
import { StyledCategories, StyledCategoriesHeader } from './styled';

export type M20CategoriesProps = M20CategoriesModule;

export type ImageAspectRatio = { mobile: [number, number]; desktop: [number, number] };

/**
 * List of category and/or page links. Can also be used to display information without links.
 *
 */
export const M20Categories = memo(
    ({
        items,
        headline,
        headlineAlignment = 'left',
        backgroundColor: initialBackgroundColor,
        use45AspectRatio,
        ...rest
    }: M20CategoriesProps) => {
        const categories = itemMapper(items);
        const categoriesLength = categories?.length ?? 0;
        const maxPerLine = categoriesLength % 3 ? 4 : 3;
        const columnCount = Math.min(categoriesLength, maxPerLine);
        const imagesVW = 100 / columnCount;
        const defaultAspectRatio: ImageAspectRatio = {
            mobile: [16, 9],
            desktop: [1800 / columnCount, 600],
        };
        const fixedAspectRatio: ImageAspectRatio = {
            mobile: [4, 5],
            desktop: [4, 5],
        };

        const { backgroundColor, headlineShade, backgroundContrastColor } = useContrastThemeShade({
            backgroundColor: initialBackgroundColor,
        });

        return (
            <ModuleContainer hasGutter backgroundColor={backgroundColor} {...rest}>
                {headline ? (
                    <StyledCategoriesHeader
                        variant="display2"
                        titleAlignment={headlineAlignment}
                        shade={headlineShade}
                    >
                        {headline}
                    </StyledCategoriesHeader>
                ) : null}
                <StyledCategories itemsPerLine={columnCount}>
                    {categories?.map((category) => {
                        return (
                            <Category
                                {...category}
                                imageAspectRatio={
                                    use45AspectRatio ? fixedAspectRatio : defaultAspectRatio
                                }
                                imagesVW={imagesVW}
                                backgroundColor={backgroundContrastColor}
                                key={weakKey(category)}
                                textPosition="below"
                            />
                        );
                    })}
                </StyledCategories>
            </ModuleContainer>
        );
    }
);
