import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { Variants } from './TextBlockCTA';
import { breakpoints } from '~/theme';

export const StyledTextBlockCTA = styled.div<{ variant?: Variants }>(
    ({ theme }) => ({
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: theme.sizes.contentCardMinHeight,
        padding: `${theme.spaces[3]} ${theme.spaces[5]}`,
    }),
    switchProp('variant', {
        expressive: ({ theme }) => ({
            alignItems: 'center',
            flexDirection: 'column',
            gap: theme.spaces[3],
            [breakpoints.md]: {
                flexDirection: 'row',
            },
        }),
    })
);

export const StyledTexts = styled.div({
    maxWidth: '70%',
    [breakpoints.md]: {
        display: '50%',
    },
});

export const StyledCTATextWrapper = styled.div({
    display: 'none',

    [breakpoints.md]: {
        display: 'block',
    },
});
