import React from 'react';
import { Link as LinkType } from '~/lib/data-contract';
import { Button, Text, TextVariants } from '~/shared/components';
import { UseThemeShadeProps, useThemeShade } from '~/theme';
import { StyledCTATextWrapper, StyledTextBlockCTA, StyledTexts } from './styled';
import RightArrow from '$icons/arrow-right.svg';
import { useTheme } from '@emotion/react';

export type Variants = 'minimized' | 'expressive';

type Props = {
    headline?: string;
    headlineVariant?: TextVariants;
    text?: string;
    subheadline?: string;
    subHeadlineUppercase?: boolean;
    callToAction?: LinkType;
    variant?: Variants;
} & UseThemeShadeProps;

export const TextBlockCTA = ({
    backgroundColor,
    headline,
    subheadline,
    callToAction,
    headlineVariant,
    text,
    subHeadlineUppercase = true,
    variant,
}: Props) => {
    const theme = useTheme();
    const backgroundColorWithFallback = backgroundColor ?? theme.traits.background.default;
    const moduleTheme = useThemeShade({ backgroundColor: backgroundColorWithFallback });
    const CTATextWrapper = variant === 'expressive' ? React.Fragment : StyledCTATextWrapper;

    return (
        <StyledTextBlockCTA
            style={{ backgroundColor: moduleTheme.backgroundColor }}
            variant={variant}
        >
            <StyledTexts>
                {subheadline && (
                    <Text
                        shade={moduleTheme.headlineShade}
                        variant={subHeadlineUppercase ? 'overline' : 'caption'}
                    >
                        {subheadline}
                    </Text>
                )}
                {headline && (
                    <Text variant={headlineVariant} shade={moduleTheme.headlineShade}>
                        {headline}
                    </Text>
                )}
                {text && (
                    <Text shade={moduleTheme.textShade} variant="body">
                        {text}
                    </Text>
                )}
            </StyledTexts>

            {callToAction?.url && (
                <Button
                    as="a"
                    href={callToAction.url}
                    variant={variant === 'expressive' ? 'primary' : 'tertiary'}
                    shade={moduleTheme.buttonShade}
                    showHoverIndicator={false}
                >
                    <CTATextWrapper>{callToAction.text || callToAction.title}</CTATextWrapper>
                    {variant !== 'expressive' && <RightArrow />}
                </Button>
            )}
        </StyledTextBlockCTA>
    );
};
